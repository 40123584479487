@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.bars{
    animation: nav 0.3s linear;
}
.cancel{
    animation: nav2 0.3s linear;
}

@keyframes nav {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(180deg);
    }
}
@keyframes nav2 {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-180deg);
    }
}
.n{
    cursor: pointer;
}
.n::after{
    content: ' ';
    width: 0px;
    height: 2px;
    position: absolute;
    top: 22px;
    left: 0;
    background-color: orange;
    transition: 0.5s ease-in-out;
}
.n:hover::after{
    width: 100%;
}
.ls{
    animation: ls 0.4s linear;
    /* animation: reverse-ls 0.4s linear; */
}
@keyframes ls {
    0%{
        right: -100%;
    }
    100%{
        right: 0;
    }
}
@keyframes reverse-ls {
    0% {
        right: 0;
    }
    100% {
        right: -100%;
    }
}
.gr{
    filter: grayscale(10%);
}
/* .slick-current{
    transform: scale(1.2);
} */

/*  */

  .test{
    background-image: url('./Images/Rectangle\ 53.png');
  }
  .banner{
    background-image: url('./Images/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .consultation{
    background-image: url('./Images/consultation.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .ourservicesbg{
    background-image: url('./Images/Group\ 35.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
/* div {
    height: 100%; 
} */

/* h1 {
    background-image: url('./Images/Vector\ 8.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%; 
    text-align: center;
    padding-top: 8px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 1.25rem; 
} */
/* .b{
    background-image: url("./Images/blackeagle.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    opacity:0.6;
   


    
    
   } */
.slick-dots {
    bottom: 0px;
   }

.slick-dots li button::before{
    color: orange;

   }
 
.wesf::after{
  content: " ";
  height: 4px;
  width: 100%;
  background-color: orange;
  position: absolute;
  bottom: -10px;
  right: 0;
  border-radius: 8px;
}
.wesf2::after{
  content: " ";
  height: 4px;
  width: 100%;
  background-color: orange;
  position: absolute;
  top: -10px;
  right: 0;
  border-radius: 8px;
}

.lh{
  line-height: 2;
}

.vm{
  background-image: url("./Images/plainbg.jpeg");
}

.wsua{
  background-image: url('./Images/whatsetsusapart.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
}

.bghover:hover >div{
    color: black;
    background-color: #FD5D13;
    transition: 0.3s ease-in-out;
  
  } 
  
  .bghover:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity));
    transition: 0.3s ease-in-out;
    animation: bounce1 1s  ease-in-out infinite;
  }
  .bghover:hover > p{
    color: white;
    transition: 0.3s ease-in-out;
   
  }
  
  @keyframes bounce1 {
    0%{
      transform: translateY(-4px);
    }
    50%{
      transform: translateY(0);
    }
  100%{
    transform: translateY(-4px);
  }
    
  }

  

.overlay{
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
  }
  .parent:hover .overlay{
    transform: translateX(0);
    transition: 0.3 ease-in-out;
    z-index: -1;
    box-shadow: black;
  }
  .parent:hover .icon{
    background-color: white;
    color: #2850A3;
  }
  .parent:hover .pf,.parent:hover .h1f{
    color: white;
  }
  .parent:hover .buttons{
    background-color: white;
    color: black;

  }
  .parent{
    box-shadow: 2px 2px 15px gray;
  }